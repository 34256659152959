export const projectContent = [
  {
    id: '1',
    name: {
      EN: 'Shift - Recommendation',
      ID: 'Rekomandasi Shift'
    },
    description: {
      EN: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Background</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Effective management of shift schedules and event budgeting is vital for ensuring operational efficiency in contemporary businesses. Despite its importance, companies frequently encounter difficulties in automating these tasks. To address these challenges, we have developed a Shift Recommendation System designed specifically for PT Salam Pacific Indonesia Lines. This innovative system not only facilitates the management of security shift schedules but also aids in the creation of event budgets (RAB). Additionally, it offers flexibility by supporting manual adjustments for unforeseen events.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Problem Statement</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The primary issues addressed by this project are outlined as follows:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>How can we automate the generation of security shift schedules and event budgets to enhance both efficiency and accuracy?</li>
  <li>How can the system be designed to accommodate both planned and emergent events with adequate flexibility?</li>
  <li>How can users be provided with a web-based interface to manually adjust schedules and budgets as needed?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Project Scope</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The scope of this project encompasses the development of a Shift Recommendation System for PT Salam Pacific Indonesia Lines. This system is designed to automate the creation of shift schedules for security personnel and to generate budgets for various company events. The system leverages automation and artificial intelligence (AI) to streamline these processes while retaining the capability for manual modifications in response to unforeseen events.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Objective</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The objectives of this project are as follows:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>To create an automated system that utilizes AI for generating security shift schedules and event budgets.</li>
  <li>To develop a web interface that enables users to add, delete, and modify schedules and budgets.</li>
  <li>To ensure the system's capability to effectively manage both scheduled and ad-hoc events.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Benefits</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The Shift Recommendation System provides several benefits, including:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Enhanced efficiency in shift scheduling and budgeting through the application of automation techniques.</li>
  <li>Increased flexibility due to the system's ability to accommodate manual adjustments for unexpected events.</li>
  <li>Streamlined operational processes and a reduction in administrative workload.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Methodology</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The methodology for developing the Shift Recommendation System is structured into several detailed phases, each contributing to the comprehensive functionality of the system. This approach ensures the systematic development and effective implementation of the system.</p>

<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>System Design:</strong> The initial phase involves designing a web-based application that provides a user-friendly interface for managing security shift schedules and event budgets. The design process focuses on creating an intuitive layout that facilitates ease of use and efficient interaction with the system’s features.</li>
  
  <li><strong>Tech Stack:</strong> The system is built using a carefully selected technology stack. Flask is employed for backend development due to its lightweight nature and flexibility in handling web requests. Jinja2 is used for rendering HTML templates, ensuring dynamic content presentation. PostgreSQL serves as the database management system, chosen for its robustness, scalability, and support for complex queries. This tech stack supports the creation of a reliable and high-performance application.</li>
  
  <li><strong>AI Integration:</strong> Artificial Intelligence (AI) is integrated to automate the generation of shift schedules and budgets. This involves developing and training machine learning models that analyze historical data and user inputs to predict optimal schedules and budgets. The AI algorithms are selected based on their ability to handle large datasets and provide accurate predictions.</li>
  
  <li><strong>Database Management:</strong> A PostgreSQL database is configured to manage and store data related to shift schedules and budgets. The database schema is designed to support efficient data retrieval and manipulation, with features allowing users to add, update, and delete records. Data integrity and consistency are maintained through rigorous validation and constraint enforcement.</li>
  
  <li><strong>Testing and Evaluation:</strong> Comprehensive testing is conducted to ensure the accuracy and reliability of the scheduling and budgeting functionalities. This includes unit tests for individual components and integration tests for the overall system. The system’s performance is evaluated through user feedback, focusing on usability, accuracy, and the effectiveness of the automated features. Adjustments are made based on test results to enhance system performance.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Discussion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The development and implementation of the Shift Recommendation System have effectively addressed the challenges associated with automating shift scheduling and event budgeting. The integration of advanced technologies, including AI and a robust tech stack, has enabled the creation of a system that significantly improves operational efficiency.</p>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The application of AI algorithms in the system has proven to be a key factor in its success. By leveraging machine learning techniques, the system is capable of generating accurate and efficient shift schedules and budgets. This automation reduces the administrative burden and minimizes human error, thereby enhancing overall productivity.</p>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The choice of Flask, Jinja2, and PostgreSQL has been instrumental in achieving a high-performance system. Flask’s flexibility allows for easy adaptation and extension of the application, while Jinja2 provides dynamic content rendering capabilities. PostgreSQL’s reliability ensures robust data management and supports complex query operations, which are essential for handling the system’s requirements.</p>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">However, several challenges were encountered during the project:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>AI Model Accuracy:</strong> Ensuring the accuracy of the AI model in generating reliable schedules and budgets required continuous refinement and validation. Despite significant efforts, the model's predictions must be regularly updated to accommodate changing data patterns.</li>
  <li><strong>Data Consistency and Integrity:</strong> Maintaining data consistency and integrity within the PostgreSQL database posed challenges, particularly in managing concurrent data modifications and ensuring that data remains accurate and up-to-date.</li>
  <li><strong>User Interaction:</strong> Facilitating smooth and intuitive user interaction for manual adjustments was crucial. Ensuring that the web interface is responsive and user-friendly required iterative design improvements based on user feedback.</li>
</ul>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Future research and development should focus on several areas to further enhance the system:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Advanced AI Techniques:</strong> Exploring more sophisticated AI methodologies, such as deep learning or reinforcement learning, could improve the system’s predictive capabilities and automation accuracy.</li>
  <li><strong> Notifications:</strong> Incorporating  notifications and alerts could enhance the system’s responsiveness and keep users informed of critical updates and changes.</li>
  <li><strong>Expanded Functionality:</strong> Extending the system’s capabilities to accommodate a wider range of events and scheduling requirements could make it more versatile and applicable to various organizational needs.</li>
</ul>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Overall, the Shift Recommendation System represents a significant advancement in automating shift scheduling and budgeting processes. The integration of AI and advanced web technologies has provided a powerful tool for PT Salam Pacific Indonesia Lines, and continued development will be essential to addressing emerging challenges and optimizing system performance.</p>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Conclusion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The Shift Recommendation System has demonstrated the efficacy of combining AI with web technologies to solve the challenges associated with shift scheduling and event budgeting. By automating these processes, the system has achieved significant improvements in efficiency and flexibility, accommodating both planned and emergent events.</p>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The project successfully met its objectives, offering a practical and effective solution for PT Salam Pacific Indonesia Lines. Ongoing development efforts will focus on refining AI capabilities and expanding system features to further optimize the scheduling and budgeting processes.</p>

      `,
      ID: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Latar Belakang</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Manajemen yang efektif dari jadwal shift dan penganggaran acara sangat penting untuk memastikan efisiensi operasional dalam bisnis kontemporer. Meskipun penting, perusahaan sering menghadapi kesulitan dalam mengotomatisasi tugas-tugas ini. Untuk mengatasi tantangan ini, kami telah mengembangkan Sistem Rekomendasi Shift yang dirancang khusus untuk PT Salam Pacific Indonesia Lines. Sistem inovatif ini tidak hanya memfasilitasi manajemen jadwal shift keamanan tetapi juga membantu dalam pembuatan anggaran acara (RAB). Selain itu, sistem ini menawarkan fleksibilitas dengan mendukung penyesuaian manual untuk kejadian yang tidak terduga.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Pernyataan Masalah</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Masalah utama yang ditangani oleh proyek ini adalah sebagai berikut:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Bagaimana kita dapat mengotomatisasi pembuatan jadwal shift keamanan dan anggaran acara untuk meningkatkan efisiensi dan akurasi?</li>
  <li>Bagaimana sistem dapat dirancang untuk mengakomodasi kejadian yang direncanakan dan mendesak dengan fleksibilitas yang memadai?</li>
  <li>Bagaimana pengguna dapat diberikan antarmuka berbasis web untuk menyesuaikan jadwal dan anggaran secara manual sesuai kebutuhan?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Lingkup Proyek</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Lingkup proyek ini mencakup pengembangan Sistem Rekomendasi Shift untuk PT Salam Pacific Indonesia Lines. Sistem ini dirancang untuk mengotomatisasi pembuatan jadwal shift untuk personel keamanan dan untuk menghasilkan anggaran untuk berbagai acara perusahaan. Sistem ini memanfaatkan otomatisasi dan kecerdasan buatan (AI) untuk merampingkan proses ini sambil mempertahankan kemampuan untuk modifikasi manual sebagai respons terhadap kejadian yang tidak terduga.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Tujuan</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tujuan dari proyek ini adalah sebagai berikut:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Menciptakan sistem otomatis yang memanfaatkan AI untuk menghasilkan jadwal shift keamanan dan anggaran acara.</li>
  <li>Mengembangkan antarmuka web yang memungkinkan pengguna untuk menambahkan, menghapus, dan memodifikasi jadwal dan anggaran.</li>
  <li>Memastikan kemampuan sistem untuk secara efektif mengelola acara yang dijadwalkan dan mendesak.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Manfaat</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Sistem Rekomendasi Shift menyediakan beberapa manfaat, termasuk:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Peningkatan efisiensi dalam penjadwalan shift dan penganggaran melalui penerapan teknik otomatisasi.</li>
  <li>Peningkatan fleksibilitas karena kemampuan sistem untuk mengakomodasi penyesuaian manual untuk kejadian yang tidak terduga.</li>
  <li>Proses operasional yang lebih efisien dan pengurangan beban administratif.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Metodologi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Metodologi untuk mengembangkan Sistem Rekomendasi Shift terstruktur dalam beberapa fase rinci, masing-masing berkontribusi pada fungsi sistem yang komprehensif. Pendekatan ini memastikan pengembangan yang sistematis dan penerapan sistem yang efektif.</p>

<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Desain Sistem:</strong> Fase awal melibatkan perancangan aplikasi berbasis web yang menyediakan antarmuka yang ramah pengguna untuk mengelola jadwal shift keamanan dan anggaran acara. Proses desain berfokus pada penciptaan tata letak yang intuitif yang memfasilitasi kemudahan penggunaan dan interaksi yang efisien dengan fitur sistem.</li>
  
  <li><strong>Teknologi yang Digunakan:</strong> Sistem ini dibangun menggunakan teknologi yang dipilih dengan hati-hati. Flask digunakan untuk pengembangan backend karena sifatnya yang ringan dan fleksibilitasnya dalam menangani permintaan web. Jinja2 digunakan untuk merender template HTML, memastikan presentasi konten dinamis. PostgreSQL berfungsi sebagai sistem manajemen basis data, dipilih karena ketahanannya, skalabilitasnya, dan dukungannya untuk kueri yang kompleks. Teknologi ini mendukung pembuatan aplikasi yang andal dan berkinerja tinggi.</li>
  
  <li><strong>Integrasi AI:</strong> Kecerdasan Buatan (AI) diintegrasikan untuk mengotomatisasi pembuatan jadwal shift dan anggaran. Ini melibatkan pengembangan dan pelatihan model pembelajaran mesin yang menganalisis data historis dan masukan pengguna untuk memprediksi jadwal dan anggaran yang optimal. Algoritma AI dipilih berdasarkan kemampuannya untuk menangani kumpulan data besar dan memberikan prediksi yang akurat.</li>
  
  <li><strong>Manajemen Basis Data:</strong> Basis data PostgreSQL dikonfigurasikan untuk mengelola dan menyimpan data terkait jadwal shift dan anggaran. Skema basis data dirancang untuk mendukung pengambilan dan manipulasi data yang efisien, dengan fitur yang memungkinkan pengguna untuk menambahkan, memperbarui, dan menghapus catatan. Integritas dan konsistensi data dipertahankan melalui validasi ketat dan penegakan batasan.</li>
  
  <li><strong>Pengujian dan Evaluasi:</strong> Pengujian yang komprehensif dilakukan untuk memastikan akurasi dan keandalan fungsionalitas penjadwalan dan penganggaran. Ini termasuk pengujian unit untuk komponen individual dan pengujian integrasi untuk keseluruhan sistem. Kinerja sistem dievaluasi melalui umpan balik pengguna, dengan fokus pada kegunaan, akurasi, dan efektivitas fitur otomatis. Penyesuaian dilakukan berdasarkan hasil pengujian untuk meningkatkan kinerja sistem.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Diskusi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan dan penerapan Sistem Rekomendasi Shift telah secara efektif mengatasi tantangan yang terkait dengan otomasi penjadwalan shift dan penganggaran acara. Integrasi teknologi canggih, termasuk AI dan tumpukan teknologi yang kuat, telah memungkinkan penciptaan sistem yang secara signifikan meningkatkan efisiensi operasional.</p>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Penerapan algoritma AI dalam sistem telah terbukti menjadi faktor kunci keberhasilannya. Dengan memanfaatkan teknik pembelajaran mesin, sistem ini mampu menghasilkan jadwal shift dan anggaran yang akurat dan efisien. Otomatisasi ini mengurangi beban administratif dan meminimalkan kesalahan manusia, sehingga meningkatkan produktivitas secara keseluruhan.</p>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pilihan Flask, Jinja2, dan PostgreSQL telah menjadi alat penting dalam mencapai sistem berkinerja tinggi. Fleksibilitas Flask memungkinkan adaptasi dan perpanjangan aplikasi dengan mudah, sementara Jinja2 menyediakan kemampuan rendering konten dinamis. Keandalan PostgreSQL memastikan manajemen data yang kuat dan mendukung operasi kueri yang kompleks, yang penting untuk menangani persyaratan sistem.</p>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Namun, beberapa tantangan dihadapi selama proyek:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Akurasi Model AI:</strong> Memastikan akurasi model AI dalam menghasilkan jadwal dan anggaran yang dapat diandalkan memerlukan penyempurnaan dan validasi yang terus menerus. Meskipun upaya yang signifikan, prediksi model harus diperbarui secara berkala untuk mengakomodasi perubahan pola data.</li>
  <li><strong>Konsistensi dan Integritas Data:</strong> Mempertahankan konsistensi dan integritas data dalam basis data PostgreSQL menghadirkan tantangan, terutama dalam mengelola modifikasi data bersamaan dan memastikan bahwa data tetap akurat dan mutakhir.</li>
  <li><strong>Interaksi Pengguna:</strong> Memfasilitasi interaksi pengguna yang mulus dan intuitif untuk penyesuaian manual adalah penting. Memastikan bahwa antarmuka web responsif dan ramah pengguna memerlukan perbaikan desain iteratif berdasarkan umpan balik pengguna.</li>
</ul>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Penelitian dan pengembangan di masa depan harus fokus pada beberapa area untuk lebih meningkatkan sistem:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Teknik AI Lanjutan:</strong> Mengeksplorasi metodologi AI yang lebih canggih, seperti pembelajaran mendalam atau pembelajaran penguatan, dapat meningkatkan kemampuan prediktif sistem dan akurasi otomasi.</li>
  <li><strong>Pemberitahuan :</strong> Mengintegrasikan pemberitahuan dan peringatan  dapat meningkatkan responsivitas sistem dan membuat pengguna tetap terinformasi tentang pembaruan dan perubahan penting.</li>
  <li><strong>Fungsionalitas Diperluas:</strong> Memperluas kemampuan sistem untuk mengakomodasi berbagai acara dan persyaratan penjadwalan dapat membuatnya lebih serbaguna dan berlaku untuk berbagai kebutuhan organisasi.</li>
</ul>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Secara keseluruhan, Sistem Rekomendasi Shift mewakili kemajuan signifikan dalam mengotomatisasi proses penjadwalan shift dan penganggaran. Integrasi AI dan teknologi web canggih telah memberikan alat yang kuat untuk PT Salam Pacific Indonesia Lines, dan pengembangan berkelanjutan akan menjadi penting untuk mengatasi tantangan yang muncul dan mengoptimalkan kinerja sistem.</p>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Kesimpulan</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Sistem Rekomendasi Shift telah menunjukkan efektivitas menggabungkan AI dengan teknologi web untuk mengatasi tantangan yang terkait dengan penjadwalan shift dan penganggaran acara. Dengan mengotomatisasi proses ini, sistem telah mencapai peningkatan signifikan dalam efisiensi dan fleksibilitas, mengakomodasi kejadian yang direncanakan dan mendesak.</p>

<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Proyek ini berhasil mencapai tujuannya, menawarkan solusi praktis dan efektif untuk PT Salam Pacific Indonesia Lines. Upaya pengembangan yang berkelanjutan akan difokuskan pada penyempurnaan kemampuan AI dan perluasan fitur sistem untuk lebih mengoptimalkan proses penjadwalan dan penganggaran.</p>

        
      `,
    },
    image: '/assets/shift-recommendation.png',
    tag: 'Artificial Intelligence',
    client: 'PT Salam Pacific Indonesia Lines',
    languages: ['Flask' ],
    stack: ['Flask'],
    link:''
  },
  {
    id: '2',
    name: {
      EN: 'Brain Tumor Detection',
      ID: 'Deteksi Tumor Otak'
    },
    description: {
      EN: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Background</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Accurate and efficient detection of brain tumors is paramount in improving patient outcomes and treatment planning. Traditional diagnostic approaches, such as manual examination of MRI scans, are often labor-intensive and susceptible to human error. This project leverages the Xception model, a state-of-the-art convolutional neural network (CNN), to automate brain tumor detection from MRI scans. By employing a dataset of labeled MRI images, the project aims to enhance diagnostic accuracy and efficiency, thereby supporting medical professionals in delivering timely and precise diagnoses.</p>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Problem Statement</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The primary issues addressed by this project include:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>How can advanced machine learning techniques be utilized to improve the accuracy of brain tumor detection from MRI scans?</li>
  <li>In what ways can the Xception model be effectively applied to analyze MRI images and generate reliable probability scores for tumor presence?</li>
  <li>How can the integration of this AI-based tool support medical professionals in streamlining and enhancing the diagnostic process?</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Project Scope</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The scope of this project encompasses the development of a brain tumor detection system using the Xception model. Key aspects include:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Implementing the Xception CNN architecture for the analysis of MRI images.</li>
  <li>Utilizing a labeled dataset of MRI scans to train, validate, and test the model.</li>
  <li>Developing a tool that outputs probability scores indicating the presence of tumors, thereby aiding medical professionals in diagnosis.</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Objectives</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The primary objectives of this project are:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>To deploy the Xception model for the automated detection of brain tumors from MRI scans.</li>
  <li>To achieve high diagnostic accuracy by accurately identifying tumors through the analysis of MRI images.</li>
  <li>To enhance the diagnostic workflow by providing reliable probability scores, leading to improved patient outcomes and more efficient diagnosis.</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Benefits</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The brain tumor detection tool offers several benefits:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Improved diagnostic accuracy and efficiency through advanced machine learning techniques.</li>
  <li>Reduction in diagnostic time and human error by automating the detection process.</li>
  <li>Support for medical professionals with reliable probability scores, enhancing the decision-making process and patient care.</li>
</ol>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Methodology</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The development of the brain tumor detection system involves the following key phases:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Data Collection and Preprocessing:</strong> Gathering a comprehensive dataset of labeled MRI images. Preprocessing steps include normalization, resizing, and augmentation to enhance the model's ability to generalize across various MRI scans.</li>
  <li><strong>Model Development:</strong> Implementing the Xception CNN architecture, known for its efficiency in feature extraction. The model is trained using the prepared dataset, and hyperparameters are optimized to achieve the best performance.</li>
  <li><strong>Training and Validation:</strong> The model undergoes training with a training set and validation with a separate dataset to monitor performance and prevent overfitting. Techniques such as cross-validation and early stopping are employed to ensure robust model performance.</li>
  <li><strong>Evaluation:</strong> The trained model is evaluated using metrics such as accuracy, precision, recall, and the area under the receiver operating characteristic curve (AUC-ROC) to assess its effectiveness in detecting brain tumors.</li>
  <li><strong>Deployment:</strong> Integrating the model into a user-friendly interface that allows medical professionals to input MRI images and receive probability scores indicating the likelihood of tumor presence. The system is designed to facilitate ease of use and provide actionable insights.</li>
</ol>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Discussion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The implementation of the Xception model for brain tumor detection has addressed several challenges in traditional diagnostic methods:</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The application of CNNs, particularly the Xception model, has demonstrated significant improvements in the accuracy and efficiency of brain tumor detection. By automating the analysis of MRI images, the model reduces the dependency on manual inspection, thus minimizing human error and diagnostic delays. The system's ability to provide probability scores supports medical professionals in making more informed decisions, potentially leading to better patient outcomes.</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Several challenges were encountered during the project:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Model Accuracy:</strong> Achieving high accuracy required extensive training and fine-tuning of the Xception model. Continuous refinement is necessary to adapt to variations in MRI images and improve prediction reliability.</li>
  <li><strong>Data Quality:</strong> The quality and diversity of the MRI dataset impact the model's performance. Ensuring a representative dataset that includes various tumor types and MRI scan conditions is crucial for generalization.</li>
  <li><strong>Integration and Usability:</strong> Developing an intuitive interface that meets the needs of medical professionals required careful design and user feedback. Ensuring the tool's usability and integration into existing workflows is essential for successful adoption.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Future work should focus on:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Enhanced AI Techniques:</strong> Exploring advanced AI methods, such as ensemble learning or deep reinforcement learning, could further improve detection accuracy and model robustness.</li>
  <li><strong> Analysis:</strong> Incorporating  analysis capabilities to provide instant feedback and support during diagnostic procedures.</li>
  <li><strong>Broader Dataset:</strong> Expanding the dataset to include more diverse MRI scans and tumor types to enhance the model's generalization and reliability.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Overall, the integration of the Xception model into brain tumor detection represents a significant advancement in the field. By combining state-of-the-art machine learning techniques with practical medical applications, this project aims to improve diagnostic accuracy and support healthcare professionals in delivering better patient care.</p>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Conclusion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The brain tumor detection system using the Xception model effectively demonstrates the potential of AI in medical diagnostics. By automating the detection of tumors from MRI scans, the system improves accuracy, efficiency, and overall diagnostic workflow. Continued development and refinement of the model and tool are essential to addressing emerging challenges and enhancing the system's capabilities.</p>
      `,
      ID: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Latar Belakang</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Deteksi dan diagnosis tumor otak yang akurat dan efisien sangat penting dalam meningkatkan hasil pasien dan perencanaan pengobatan. Pendekatan diagnostik tradisional, seperti pemeriksaan manual terhadap pemindaian MRI, sering kali memerlukan waktu dan rentan terhadap kesalahan manusia. Proyek ini memanfaatkan model Xception, sebuah jaringan saraf konvolusi (CNN) yang canggih, untuk mengotomatisasi deteksi tumor otak dari pemindaian MRI. Dengan menggunakan dataset gambar MRI yang diberi label, proyek ini bertujuan untuk meningkatkan akurasi dan efisiensi diagnosis, sehingga mendukung profesional medis dalam memberikan diagnosis yang tepat waktu dan akurat.</p>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Rumusan Masalah</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Masalah utama yang diatasi oleh proyek ini meliputi:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Bagaimana teknik pembelajaran mesin canggih dapat digunakan untuk meningkatkan akurasi deteksi tumor otak dari pemindaian MRI?</li>
  <li>Bagaimana model Xception dapat diterapkan secara efektif untuk menganalisis gambar MRI dan menghasilkan skor probabilitas yang dapat diandalkan untuk keberadaan tumor?</li>
  <li>Bagaimana integrasi alat berbasis AI ini dapat mendukung profesional medis dalam menyederhanakan dan meningkatkan proses diagnosis?</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Lingkup Proyek</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Lingkup proyek ini mencakup pengembangan sistem deteksi tumor otak menggunakan model Xception. Aspek utama meliputi:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Menerapkan arsitektur CNN Xception untuk analisis gambar MRI.</li>
  <li>Memanfaatkan dataset gambar MRI yang diberi label untuk melatih, memvalidasi, dan menguji model.</li>
  <li>Mengembangkan alat yang menghasilkan skor probabilitas yang menunjukkan kemungkinan keberadaan tumor, sehingga membantu profesional medis dalam diagnosis.</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Tujuan</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tujuan utama dari proyek ini adalah:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Menerapkan model Xception untuk deteksi otomatis tumor otak dari pemindaian MRI.</li>
  <li>Mencapai akurasi tinggi dalam mengidentifikasi tumor melalui analisis gambar MRI.</li>
  <li>Enhance the diagnostic workflow by providing reliable probability scores, leading to improved patient outcomes and more efficient diagnosis.</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Manfaat</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Alat deteksi tumor otak menawarkan beberapa manfaat:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Akurasinya meningkat dan efisiensi melalui teknik pembelajaran mesin canggih.</li>
  <li>Pengurangan waktu diagnosis dan kesalahan manusia dengan mengotomatisasi proses deteksi.</li>
  <li>Dukungan untuk profesional medis dengan skor probabilitas yang dapat diandalkan, meningkatkan proses pengambilan keputusan dan perawatan pasien.</li>
</ol>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Metodologi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan sistem deteksi tumor otak melibatkan beberapa fase kunci:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Pengumpulan dan Pra-Pemrosesan Data:</strong> Mengumpulkan dataset gambar MRI yang lengkap dan diberi label. Langkah pra-pemrosesan meliputi normalisasi, pengubahan ukuran, dan augmentasi untuk meningkatkan kemampuan model dalam menggeneralisasi berbagai pemindaian MRI.</li>
  <li><strong>Pengembangan Model:</strong> Menerapkan arsitektur CNN Xception, yang dikenal efisien dalam ekstraksi fitur. Model dilatih menggunakan dataset yang dipersiapkan, dan hyperparameter dioptimalkan untuk mencapai kinerja terbaik.</li>
  <li><strong>Pelatihan dan Validasi:</strong> Model dilatih dengan set pelatihan dan divalidasi dengan dataset terpisah untuk memantau kinerja dan mencegah overfitting. Teknik seperti cross-validation dan early stopping digunakan untuk memastikan kinerja model yang robust.</li>
  <li><strong>Evaluasi:</strong> Model yang dilatih dievaluasi menggunakan metrik seperti akurasi, presisi, recall, dan area under the receiver operating characteristic curve (AUC-ROC) untuk menilai efektivitasnya dalam mendeteksi tumor otak.</li>
  <li><strong>Implementasi:</strong> Mengintegrasikan model ke dalam antarmuka yang ramah pengguna yang memungkinkan profesional medis untuk memasukkan gambar MRI dan menerima skor probabilitas yang menunjukkan kemungkinan adanya tumor. Sistem dirancang untuk memudahkan penggunaan dan memberikan wawasan yang dapat ditindaklanjuti.</li>
</ol>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Diskusi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Implementasi model Xception untuk deteksi tumor otak telah mengatasi beberapa tantangan dalam metode diagnostik tradisional:</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Penerapan CNN, khususnya model Xception, telah menunjukkan peningkatan signifikan dalam akurasi dan efisiensi deteksi tumor otak. Dengan mengotomatisasi analisis gambar MRI, model ini mengurangi ketergantungan pada pemeriksaan manual, sehingga meminimalkan kesalahan manusia dan keterlambatan diagnosis. Kemampuan sistem untuk memberikan skor probabilitas mendukung profesional medis dalam membuat keputusan yang lebih terinformasi, berpotensi meningkatkan hasil pasien.</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Beberapa tantangan dihadapi selama proyek:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Akurasi Model:</strong> Mencapai akurasi tinggi memerlukan pelatihan yang ekstensif dan penyempurnaan model Xception. Refinement berkelanjutan diperlukan untuk beradaptasi dengan variasi dalam gambar MRI dan meningkatkan keandalan prediksi.</li>
  <li><strong>Kualitas Data:</strong> Kualitas dan keragaman dataset MRI mempengaruhi kinerja model. Menjamin dataset yang representatif yang mencakup berbagai jenis tumor dan kondisi pemindaian MRI sangat penting untuk generalisasi.</li>
  <li><strong>Integrasi dan Kegunaan:</strong> Mengembangkan antarmuka yang intuitif yang memenuhi kebutuhan profesional medis memerlukan desain yang hati-hati dan umpan balik pengguna. Memastikan kegunaan alat dan integrasinya ke dalam alur kerja yang ada adalah kunci untuk adopsi yang sukses.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pekerjaan mendatang harus fokus pada:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Teknik AI Lanjutan:</strong> Menjelajahi metode AI yang lebih canggih, seperti pembelajaran mendalam atau pembelajaran penguatan, dapat lebih meningkatkan akurasi deteksi dan ketahanan model.</li>
  <li><strong>Analisis :</strong> Mengintegrasikan kemampuan analisis  untuk memberikan umpan balik instan dan mendukung selama prosedur diagnosis.</li>
  <li><strong>Dataset yang Lebih Luas:</strong> Memperluas dataset untuk mencakup lebih banyak variasi gambar MRI dan jenis tumor untuk meningkatkan generalisasi dan keandalan model.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Secara keseluruhan, integrasi model Xception dalam deteksi tumor otak mewakili kemajuan signifikan di bidang ini. Dengan menggabungkan teknik pembelajaran mesin canggih dengan aplikasi medis praktis, proyek ini bertujuan untuk meningkatkan akurasi diagnosis dan mendukung profesional kesehatan dalam memberikan perawatan pasien yang lebih baik.</p>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Kesimpulan</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Sistem deteksi tumor otak menggunakan model Xception secara efektif menunjukkan potensi AI dalam diagnostik medis. Dengan mengotomatisasi deteksi tumor dari pemindaian MRI, sistem ini meningkatkan akurasi, efisiensi, dan alur kerja diagnosis secara keseluruhan. Pengembangan dan penyempurnaan model serta alat secara berkelanjutan sangat penting untuk mengatasi tantangan yang muncul dan meningkatkan kemampuan sistem.</p>
      `,
    },
    image: '/assets/brain-tumor-detection.png',
    tag: 'Artificial Intelligence',
    client: '-',
    languages: ['Python', 'Streamlit'],
    stack: ['Python, Streamlit'],
    link:'https://github.com/gigarazkiarianda/brain-tumor-detection'
  },
  {
    id: '3',
    name: {
      EN: 'Translatica',
      ID: 'Translatica'
    },
    description: {
      EN: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Background</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Translatica is a language translation service that leverages advanced natural language processing (NLP) models from Helsinki and various other frameworks. With this cutting-edge technology, Translatica provides accurate and fast translations for Indonesian, English, and French. Currently, the service is available through the website, making it easy for users to perform instant translations online. Translatica is committed to continuously improving the quality of its services and expanding its features. In the near future, we will also launch an Android application, allowing users to access translation services more conveniently and efficiently. With Translatica, cross-language communication becomes easier and more accessible, helping to overcome language barriers in various contexts. We strive to deliver the best translation experience and support users' needs for global communication.</p>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Problem Statement</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The primary issues addressed by this project are:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>How to utilize advanced NLP technology to provide fast and accurate translations between Indonesian, English, and French?</li>
  <li>How to integrate various NLP models and frameworks to build an effective and efficient translation system?</li>
  <li>How to ensure the availability of translation services through both web platforms and Android applications to enhance user accessibility?</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Project Scope</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The scope of the Translatica project includes:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Developing the backend using the Flask framework to manage translation requests and integrate NLP models.</li>
  <li>Designing the frontend with HTML, CSS, and JavaScript to ensure an intuitive and responsive user interface.</li>
  <li>Using PostgreSQL as the database management system to store user data and translation activity logs.</li>
  <li>Implementing sustainable development strategies and feature enhancements to expand the capabilities of the translation service.</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Objectives</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The main objectives of this project are:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>To provide accurate and fast translation services between Indonesian, English, and French using the latest NLP technology.</li>
  <li>To build an efficient backend system with Flask to process translation requests and communicate with NLP models.</li>
  <li>To design a user-friendly interface with HTML, CSS, and JavaScript to ensure an optimal user experience.</li>
  <li>To leverage PostgreSQL for secure and efficient data storage and management.</li>
  <li>To develop an Android application to enhance the accessibility of translation services for mobile users.</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Benefits</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Translatica offers several benefits:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Enhanced accuracy and speed of translations through the implementation of advanced NLP models.</li>
  <li>Facilitated cross-language communication with easy access through the website and Android application.</li>
  <li>Provision of a comprehensive and reliable translation solution for various user needs.</li>
</ol>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Methodology</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The methodology for developing Translatica consists of several key phases:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>System Design:</strong> The initial phase involves designing an intuitive user interface using HTML, CSS, and JavaScript, as well as designing the backend architecture with Flask. This design aims to facilitate user interaction and ensure efficient system performance.</li>
  <li><strong>Backend Development:</strong> Flask is used to build an API that manages translation requests and communicates with the NLP models. Integration of various NLP models from Helsinki and related frameworks is performed to achieve optimal translation results.</li>
  <li><strong>Frontend Development:</strong> The user interface is designed with HTML, CSS, and JavaScript to provide a responsive and user-friendly experience. Frontend technologies are optimized for performance and accessibility.</li>
  <li><strong>Database Management:</strong> PostgreSQL is used to store data related to users and translation activity logs. The database is designed for efficiency and security, with the capability to handle large volumes of data and ensure data integrity.</li>
  <li><strong>Testing and Evaluation:</strong> Thorough testing is conducted to ensure that the system functions correctly. This includes unit testing for individual components and integration testing for the overall system. User feedback is used to evaluate and enhance the interface and system performance.</li>
</ol>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Discussion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The development of Translatica has faced several challenges and opportunities:</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The use of advanced NLP technology and frameworks enables the system to provide fast and accurate translations. The integration of models from Helsinki and other frameworks plays a crucial role in achieving high-quality translation results. A user-friendly interface and efficient backend system support smooth operations and a positive user experience.</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Challenges encountered include:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>NLP Model Integration:</strong> Integrating various NLP models and frameworks requires extensive adjustments and testing to ensure optimal performance and translation accuracy.</li>
  <li><strong>Data Management:</strong> Storing and managing data with PostgreSQL requires attention to data consistency and security, especially with large volumes of data.</li>
  <li><strong>Interface Design:</strong> Providing an intuitive and responsive interface requires careful design and iteration based on user feedback.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Future development should focus on:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Advanced NLP Techniques:</strong> Adopting more sophisticated NLP techniques to enhance translation accuracy and flexibility.</li>
  <li><strong>Mobile Application:</strong> Launching an Android application to improve accessibility and user convenience in using translation services.</li>
  <li><strong>Feature Expansion:</strong> Adding new features to support additional languages and enhance system capabilities.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Overall, Translatica represents a significant advancement in language translation services using NLP technology and web development. The system offers an efficient and accurate translation solution, with potential for continued growth and improved user experience.</p>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Conclusion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Translatica has demonstrated the effectiveness of using NLP technology in translation services. By integrating the latest models and providing access through both web and Android applications, the system facilitates cross-language communication and enhances service accessibility. Ongoing development and innovation will be key to maintaining and improving service quality in the future.</p>
      `,
      ID: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Latar Belakang</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Translatica merupakan layanan terjemahan bahasa yang memanfaatkan model pemrosesan bahasa alami (NLP) dari Helsinki dan berbagai kerangka kerja canggih lainnya. Dengan teknologi mutakhir ini, Translatica menyediakan terjemahan yang akurat dan cepat untuk bahasa Indonesia, Inggris, dan Prancis. Saat ini, layanan ini tersedia melalui situs web, memudahkan pengguna untuk melakukan terjemahan instan secara online. Translatica berkomitmen untuk terus meningkatkan kualitas layanan dan memperluas fitur-fitur yang ditawarkan. Dalam waktu dekat, kami juga akan meluncurkan aplikasi Android, yang akan memungkinkan pengguna mengakses layanan terjemahan dengan lebih nyaman dan efisien. Dengan Translatica, komunikasi lintas bahasa menjadi lebih mudah dan lebih terjangkau, membantu mengatasi hambatan bahasa dalam berbagai konteks. Kami berusaha untuk memberikan pengalaman terjemahan terbaik dan mendukung kebutuhan komunikasi global pengguna.</p>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Rumusan Masalah</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Masalah utama yang dihadapi oleh proyek ini adalah:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Bagaimana cara memanfaatkan teknologi NLP canggih untuk menyediakan terjemahan yang cepat dan akurat antara bahasa Indonesia, Inggris, dan Prancis?</li>
  <li>Bagaimana cara mengintegrasikan berbagai model NLP dan kerangka kerja untuk membangun sistem terjemahan yang efektif dan efisien?</li>
  <li>Bagaimana cara memastikan ketersediaan layanan terjemahan melalui platform web dan aplikasi Android untuk meningkatkan aksesibilitas bagi pengguna?</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Lingkup Proyek</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Lingkup proyek Translatica mencakup:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Pengembangan backend menggunakan framework Flask untuk mengelola permintaan terjemahan dan integrasi model NLP.</li>
  <li>Desain frontend dengan HTML, CSS, dan JavaScript untuk memastikan antarmuka pengguna yang intuitif dan responsif.</li>
  <li>Penggunaan PostgreSQL sebagai sistem manajemen basis data untuk menyimpan data pengguna dan log aktivitas terjemahan.</li>
  <li>Penerapan strategi pengembangan berkelanjutan dan peningkatan fitur untuk memperluas kapabilitas layanan terjemahan.</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Tujuan</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tujuan utama proyek ini adalah:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Menyediakan layanan terjemahan yang akurat dan cepat antara bahasa Indonesia, Inggris, dan Prancis menggunakan teknologi NLP terbaru.</li>
  <li>Membangun sistem backend yang efisien dengan Flask untuk memproses permintaan terjemahan dan berkomunikasi dengan model NLP.</li>
  <li>Mendesain antarmuka pengguna yang ramah dengan HTML, CSS, dan JavaScript untuk memastikan pengalaman pengguna yang optimal.</li>
  <li>Memanfaatkan PostgreSQL untuk menyimpan dan mengelola data dengan aman dan efisien.</li>
  <li>Menyiapkan aplikasi Android untuk meningkatkan aksesibilitas layanan terjemahan bagi pengguna mobile.</li>
</ol>
<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Manfaat</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Translatica menawarkan sejumlah manfaat:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Meningkatkan akurasi dan kecepatan terjemahan melalui penerapan model NLP canggih.</li>
  <li>Memudahkan komunikasi lintas bahasa dengan akses yang mudah melalui situs web dan aplikasi Android.</li>
  <li>Memberikan solusi terjemahan yang komprehensif dan dapat diandalkan untuk berbagai kebutuhan pengguna.</li>
</ol>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Metodologi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Metodologi pengembangan Translatica terdiri dari beberapa fase kunci:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Desain Sistem:</strong> Fase awal melibatkan perancangan antarmuka pengguna yang intuitif menggunakan HTML, CSS, dan JavaScript, serta merancang arsitektur backend dengan Flask. Desain ini bertujuan untuk memudahkan interaksi pengguna dan memastikan kinerja sistem yang efisien.</li>
  <li><strong>Pengembangan Backend:</strong> Flask digunakan untuk membangun API yang mengelola permintaan terjemahan dan berkomunikasi dengan model NLP. Integrasi berbagai model NLP dari Helsinki dan framework terkait dilakukan untuk mencapai hasil terjemahan yang optimal.</li>
  <li><strong>Pengembangan Frontend:</strong> Antarmuka pengguna dirancang dengan HTML, CSS, dan JavaScript untuk menyediakan pengalaman pengguna yang responsif dan ramah. Teknologi frontend dioptimalkan untuk performa dan keterjangkauan.</li>
  <li><strong>Manajemen Basis Data:</strong> PostgreSQL digunakan untuk menyimpan data terkait pengguna dan log aktivitas terjemahan. Basis data dirancang untuk efisiensi dan keamanan, dengan kemampuan untuk menangani volume data yang besar dan memastikan integritas data.</li>
  <li><strong>Pengujian dan Evaluasi:</strong> Pengujian menyeluruh dilakukan untuk memastikan bahwa sistem berfungsi dengan baik. Ini termasuk uji unit untuk komponen individual, serta uji integrasi untuk keseluruhan sistem. Umpan balik pengguna digunakan untuk mengevaluasi dan meningkatkan antarmuka serta performa sistem.</li>
</ol>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Diskusi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan Translatica telah menghadapi beberapa tantangan dan peluang:</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Penggunaan teknologi NLP dan kerangka kerja canggih memungkinkan sistem untuk menyediakan terjemahan yang cepat dan akurat. Integrasi model-model dari Helsinki dan framework lainnya berperan penting dalam mencapai hasil terjemahan yang berkualitas tinggi. Antarmuka pengguna yang ramah dan sistem backend yang efisien mendukung operasi yang mulus dan pengalaman pengguna yang positif.</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Beberapa tantangan yang dihadapi meliputi:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Integrasi Model NLP:</strong> Menyatukan berbagai model NLP dan framework memerlukan penyesuaian dan pengujian ekstensif untuk memastikan kinerja optimal dan akurasi terjemahan.</li>
  <li><strong>Pengelolaan Data:</strong> Menyimpan dan mengelola data dengan PostgreSQL memerlukan perhatian terhadap konsistensi dan keamanan data, terutama dengan volume data yang besar.</li>
  <li><strong>Desain Antarmuka:</strong> Menyediakan antarmuka yang intuitif dan responsif membutuhkan desain yang cermat dan iterasi berdasarkan umpan balik pengguna.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan mendatang harus fokus pada:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Teknik NLP Lanjutan:</strong> Mengadopsi teknik NLP yang lebih canggih untuk meningkatkan akurasi dan fleksibilitas terjemahan.</li>
  <li><strong>Aplikasi Mobile:</strong> Meluncurkan aplikasi Android untuk meningkatkan aksesibilitas dan kenyamanan pengguna dalam menggunakan layanan terjemahan.</li>
  <li><strong>Ekspansi Fitur:</strong> Menambah fitur baru untuk mendukung bahasa tambahan dan meningkatkan kapabilitas sistem.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Secara keseluruhan, Translatica mewakili kemajuan signifikan dalam layanan terjemahan bahasa menggunakan teknologi NLP dan pengembangan web. Sistem ini menawarkan solusi terjemahan yang efisien dan akurat, dengan potensi untuk terus berkembang dan meningkatkan pengalaman pengguna.</p>
<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Kesimpulan</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Translatica telah membuktikan efektivitas penggunaan teknologi NLP dalam layanan terjemahan. Dengan mengintegrasikan model-model terbaru dan menyediakan akses melalui web serta aplikasi Android, sistem ini mempermudah komunikasi lintas bahasa dan meningkatkan aksesibilitas layanan. Pengembangan berkelanjutan dan inovasi akan menjadi kunci untuk mempertahankan dan meningkatkan kualitas layanan di masa depan.</p>`,
    },
    image: '/assets/translatica.png',
    tag: 'Artificial Intelligence',
    client: '-',
    languages: ['Python', 'Flask'],
    stack: ['Python, Flask'],
    link:'https://github.com/gigarazkiarianda/translatica'
  },
  {
    id: '4',
    name: {
      EN: 'Social Media',
      ID: 'Media Sosial'
    },
    description: {
      EN: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Background</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Our project is a social media platform, designed with features reminiscent of Instagram, built using Laravel. This platform aims to offer a comprehensive and engaging user experience with functionalities that cater to modern social networking needs. By leveraging Laravel’s robust framework, the project will support a wide range of social media activities including user login, registration,  chatting, posting, liking, commenting, following, and notifications. Additionally, users will benefit from enhanced privacy and customization options, such as hiding and archiving posts, editing profiles, managing passwords, and viewing status indicators like 'last seen' and 'read receipts'. The development of this platform is focused on delivering a secure, user-friendly, and interactive social media experience.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Problem Statement</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The primary challenges addressed by this project are:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>How to implement a secure and user-friendly login and registration system for new and returning users?</li>
  <li>How to integrate  chatting and notification features to enhance user interaction and engagement?</li>
  <li>How to enable users to like, comment on posts, follow others, and manage their social network effectively?</li>
  <li>How to provide privacy features such as hiding and archiving posts, along with profile editing capabilities?</li>
  <li>How to implement status indicators like 'last seen' and 'read receipts' to improve user communication?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Project Scope</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The scope of the social media project includes:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Developing a secure backend using Laravel to handle user authentication,  messaging, and notifications.</li>
  <li>Designing a responsive frontend interface with HTML, CSS, and JavaScript to facilitate user interaction and engagement.</li>
  <li>Implementing features for post management, including liking, commenting, following, and archiving.</li>
  <li>Providing user profile customization options, including profile editing, password changes, and privacy settings.</li>
  <li>Incorporating status indicators such as 'last seen' and 'read receipts' to enhance communication between users.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Objectives</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The main objectives of this project are:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>To provide a secure and efficient user login and registration system using Laravel’s authentication features.</li>
  <li>To develop a  chatting system and notifications to enhance user interaction.</li>
  <li>To enable users to like, comment, follow, and manage posts with ease.</li>
  <li>To implement privacy features such as hiding and archiving posts, and allow users to edit profiles and change passwords.</li>
  <li>To incorporate status indicators like 'last seen' and 'read receipts' to improve user communication.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Benefits</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The social media platform offers several benefits:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Enhanced user engagement through features like  chatting, notifications, and interactive posts.</li>
  <li>Improved user privacy and control with options to hide or archive posts and customize profiles.</li>
  <li>Secure and user-friendly interface for managing social interactions, including following, liking, and commenting.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Methodology</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The methodology for developing the social media platform includes the following phases:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>System Design:</strong> Designing an intuitive user interface with HTML, CSS, and JavaScript, and planning the backend architecture with Laravel to support user interactions and data management.</li>
  <li><strong>Backend Development:</strong> Building a robust backend using Laravel to handle user authentication,  messaging, notifications, and data management.</li>
  <li><strong>Frontend Development:</strong> Creating a responsive and interactive frontend using HTML, CSS, and JavaScript to ensure a seamless user experience.</li>
  <li><strong>Feature Implementation:</strong> Implementing core features such as liking, commenting, following, and managing posts, along with privacy settings and status indicators.</li>
  <li><strong>Testing and Evaluation:</strong> Conducting thorough testing of all features and functionalities, gathering user feedback, and making necessary adjustments to enhance the overall system performance and user experience.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Discussion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The development of the social media platform has presented several challenges and opportunities:</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Leveraging Laravel’s capabilities allows for the creation of a secure and efficient system with  features and a user-friendly interface. Integrating functionalities like chatting, notifications, and privacy settings enhances user engagement and satisfaction.</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Challenges encountered include:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong> Messaging:</strong> Ensuring reliable and efficient  communication can be complex and requires careful implementation.</li>
  <li><strong>Data Privacy:</strong> Managing and securing user data, especially with features like post archiving and profile editing, demands strict attention to security practices.</li>
  <li><strong>Interface Design:</strong> Designing an intuitive and responsive user interface requires iterative testing and user feedback.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Future development should focus on:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Enhanced Features:</strong> Adding new functionalities and improving existing features to meet evolving user needs.</li>
  <li><strong>Performance Optimization:</strong> Continuously optimizing system performance to handle increasing user activity and data volume.</li>
  <li><strong>User Experience:</strong> Incorporating user feedback to refine the interface and enhance overall user satisfaction.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Overall, the social media platform represents a significant advancement in user engagement and social interaction through a well-designed and secure Laravel-based system. The project promises a dynamic and interactive social media experience with potential for future growth and enhancement.</p>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Conclusion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The social media platform effectively demonstrates the power of Laravel in building a comprehensive and interactive social networking system. By integrating features such as  messaging, notifications, privacy controls, and user customization, the platform enhances user engagement and communication. Ongoing development and innovation will be crucial in maintaining service quality and adapting to user needs in the future.</p>

      `,
      ID: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Latar Belakang</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Proyek kami adalah platform media sosial, dirancang dengan fitur-fitur yang mirip dengan Instagram, dibangun menggunakan Laravel. Platform ini bertujuan untuk menawarkan pengalaman pengguna yang komprehensif dan menarik dengan fungsionalitas yang memenuhi kebutuhan jejaring sosial modern. Dengan memanfaatkan kerangka kerja Laravel yang kuat, proyek ini akan mendukung berbagai aktivitas media sosial termasuk login pengguna, pendaftaran, chatting , posting, menyukai, mengomentari, mengikuti, dan notifikasi. Selain itu, pengguna akan mendapatkan manfaat dari opsi privasi dan kustomisasi yang ditingkatkan, seperti menyembunyikan dan mengarsipkan posting, mengedit profil, mengelola kata sandi, dan melihat indikator status seperti 'terakhir dilihat' dan 'baca'. Pengembangan platform ini berfokus pada penyediaan pengalaman media sosial yang aman, ramah pengguna, dan interaktif.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Pernyataan Masalah</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tantangan utama yang dihadapi oleh proyek ini adalah:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Bagaimana cara mengimplementasikan sistem login dan pendaftaran yang aman dan ramah pengguna untuk pengguna baru dan yang sudah ada?</li>
  <li>Bagaimana cara mengintegrasikan fitur chatting  dan notifikasi untuk meningkatkan interaksi dan keterlibatan pengguna?</li>
  <li>Bagaimana cara memungkinkan pengguna untuk menyukai, mengomentari posting, mengikuti orang lain, dan mengelola jaringan sosial mereka dengan efektif?</li>
  <li>Bagaimana cara menyediakan fitur privasi seperti menyembunyikan dan mengarsipkan posting, serta kemampuan untuk mengedit profil?</li>
  <li>Bagaimana cara mengimplementasikan indikator status seperti 'terakhir dilihat' dan 'baca' untuk meningkatkan komunikasi pengguna?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Ruang Lingkup Proyek</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Ruang lingkup proyek media sosial ini meliputi:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Mengembangkan backend yang aman menggunakan Laravel untuk menangani otentikasi pengguna, messaging, dan notifikasi.</li>
  <li>Merancang antarmuka frontend yang responsif dengan HTML, CSS, dan JavaScript untuk memfasilitasi interaksi dan keterlibatan pengguna.</li>
  <li>Menerapkan fitur-fitur untuk manajemen posting, termasuk menyukai, mengomentari, mengikuti, dan mengarsipkan.</li>
  <li>Menyediakan opsi kustomisasi profil pengguna, termasuk pengeditan profil, perubahan kata sandi, dan pengaturan privasi.</li>
  <li>Inkorporasi indikator status seperti 'terakhir dilihat' dan 'baca' untuk meningkatkan komunikasi antar pengguna.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Tujuan</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tujuan utama proyek ini adalah:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Memberikan sistem login dan pendaftaran yang aman dan efisien menggunakan fitur otentikasi Laravel.</li>
  <li>Mengembangkan sistem chatting  dan notifikasi untuk meningkatkan interaksi pengguna.</li>
  <li>Memungkinkan pengguna untuk menyukai, mengomentari, mengikuti, dan mengelola posting dengan mudah.</li>
  <li>Menerapkan fitur privasi seperti menyembunyikan dan mengarsipkan posting, serta memungkinkan pengguna untuk mengedit profil dan mengubah kata sandi.</li>
  <li>Inkorporasi indikator status seperti 'terakhir dilihat' dan 'baca' untuk meningkatkan komunikasi pengguna.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Manfaat</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Platform media sosial ini menawarkan beberapa manfaat:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Keterlibatan pengguna yang meningkat melalui fitur-fitur seperti chatting , notifikasi, dan posting interaktif.</li>
  <li>Privasi dan kontrol pengguna yang ditingkatkan dengan opsi untuk menyembunyikan atau mengarsipkan posting dan menyesuaikan profil.</li>
  <li>Antarmuka yang aman dan ramah pengguna untuk mengelola interaksi sosial, termasuk mengikuti, menyukai, dan mengomentari.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Metodologi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Metodologi untuk mengembangkan platform media sosial ini meliputi fase-fase berikut:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Desain Sistem:</strong> Merancang antarmuka pengguna yang intuitif dengan HTML, CSS, dan JavaScript, serta merencanakan arsitektur backend dengan Laravel untuk mendukung interaksi pengguna dan manajemen data.</li>
  <li><strong>Pembangunan Backend:</strong> Membangun backend yang kuat menggunakan Laravel untuk menangani otentikasi pengguna, messaging, notifikasi, dan manajemen data.</li>
  <li><strong>Pembangunan Frontend:</strong> Membuat frontend yang responsif dan interaktif menggunakan HTML, CSS, dan JavaScript untuk memastikan pengalaman pengguna yang mulus.</li>
  <li><strong>Implementasi Fitur:</strong> Menerapkan fitur-fitur inti seperti menyukai, mengomentari, mengikuti, dan mengelola posting, bersama dengan pengaturan privasi dan indikator status.</li>
  <li><strong>Pengujian dan Evaluasi:</strong> Melakukan pengujian menyeluruh dari semua fitur dan fungsionalitas, mengumpulkan umpan balik pengguna, dan melakukan penyesuaian yang diperlukan untuk meningkatkan kinerja sistem dan pengalaman pengguna secara keseluruhan.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Diskusi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan platform media sosial ini telah menghadapi beberapa tantangan dan peluang:</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Memanfaatkan kemampuan Laravel memungkinkan penciptaan sistem yang aman dan efisien dengan fitur  dan antarmuka yang ramah pengguna. Mengintegrasikan fungsionalitas seperti chatting, notifikasi, dan pengaturan privasi meningkatkan keterlibatan dan kepuasan pengguna.</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tantangan yang dihadapi meliputi:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Messaging :</strong> Menjamin komunikasi  yang andal dan efisien dapat menjadi kompleks dan memerlukan implementasi yang hati-hati.</li>
  <li><strong>Privasi Data:</strong> Mengelola dan mengamankan data pengguna, terutama dengan fitur seperti pengarsipan posting dan pengeditan profil, memerlukan perhatian ketat pada praktik keamanan.</li>
  <li><strong>Desain Antarmuka:</strong> Merancang antarmuka pengguna yang intuitif dan responsif memerlukan pengujian iteratif dan umpan balik pengguna.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan di masa depan harus fokus pada:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Fitur yang Ditingkatkan:</strong> Menambahkan fungsionalitas baru dan meningkatkan fitur yang ada untuk memenuhi kebutuhan pengguna yang berkembang.</li>
  <li><strong>Optimisasi Kinerja:</strong> Terus-menerus mengoptimalkan kinerja sistem untuk menangani aktivitas pengguna dan volume data yang meningkat.</li>
  <li><strong>Pengalaman Pengguna:</strong> Menggabungkan umpan balik pengguna untuk menyempurnakan antarmuka dan meningkatkan kepuasan pengguna secara keseluruhan.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Secara keseluruhan, platform media sosial ini merupakan kemajuan signifikan dalam keterlibatan pengguna dan interaksi sosial melalui sistem berbasis Laravel yang dirancang dengan baik. Proyek ini menjanjikan pengalaman media sosial yang dinamis dan interaktif dengan potensi untuk pertumbuhan dan peningkatan di masa depan.</p>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Kesimpulan</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Platform media sosial ini secara efektif menunjukkan kekuatan Laravel dalam membangun sistem jejaring sosial yang komprehensif dan interaktif. Dengan mengintegrasikan fitur seperti messaging , notifikasi, kontrol privasi, dan kustomisasi pengguna, platform ini meningkatkan keterlibatan dan komunikasi pengguna. Pengembangan dan inovasi yang berkelanjutan akan menjadi kunci dalam mempertahankan kualitas layanan dan menyesuaikan dengan kebutuhan pengguna di masa depan.</p>
`,
    },
    image: '/assets/social-media.png',
    tag: 'Website Developer',
    client: '-',
    languages: ['Laravel', 'Mysql'],
    stack: ['Laravel, Mysql'],
    link: 'https://github.com/gigarazkiarianda/SocialMedia'
  },

  {
    id: '5',
    name: {
      EN: 'Ta Alla Villa ',
      ID: 'Villa Ta Alla'
    },
    description: {
      EN: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Background</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Taalla Villa is a profile website offering villa rentals in Kota Batu. Built using React JS, this website is designed as a landing page aimed at providing comprehensive information about the villa's facilities, location, and rental options. With a modern and responsive design, Taalla Villa offers users a seamless and fast experience to find and rent the best villas. The use of React JS in web development supports fast loading speeds and smooth navigation. Additional features such as image galleries, testimonials, and villa availability are also available to assist potential renters in making the best decision.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Problem Statement</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The main challenges encountered during the development of this website include:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>How to create a visually appealing landing page while keeping it fast and responsive across various devices?</li>
  <li>How to present villa information, facilities, prices, and availability in an intuitive and easily accessible way?</li>
  <li>How to integrate an interactive image gallery and customer testimonials to boost trust for potential renters?</li>
  <li>How to maintain the site's security and speed as visitor traffic increases, especially during the holiday season?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Project Scope</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The scope of the Taalla Villa website development includes:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Building the landing page using React JS to support a fast and responsive user experience.</li>
  <li>Designing a modern, user-friendly interface with intuitive navigation features.</li>
  <li>Implementing a villa photo gallery, facility information, and rental prices that are easily accessible.</li>
  <li>Adding customer testimonials and reviews to boost trust from potential renters.</li>
  <li>Providing contact information, location map, and reservation options directly connected to the villa owners.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Objectives</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The main objectives of this website development are:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>To provide comprehensive information about the villa, including facilities, prices, and availability, through a responsive and easily accessible landing page.</li>
  <li>To enhance the visual appeal of the site with an image gallery and customer testimonials to attract more potential renters.</li>
  <li>To provide an easy way for potential renters to make reservations or inquiries through direct contact features and an integrated location map.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Benefits</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Taalla Villa offers several key benefits to website visitors:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Improved accessibility and convenience in searching and selecting villas in Kota Batu with clear and comprehensive information.</li>
  <li>Enhanced user experience through a user-friendly interface and fast performance thanks to the use of React JS.</li>
  <li>Ease of making reservations and direct contact with the owner through integrated contact forms and location maps.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Methodology</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The Taalla Villa website development followed several phases of methodology:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>System Design:</strong> Designing the user interface with accessibility and speed in mind, as well as planning the architecture using React JS to support fast and responsive navigation experiences.</li>
  <li><strong>Frontend Development:</strong> Creating an interactive and dynamic landing page using React JS, supported by CSS and JavaScript to ensure optimal user experience across various devices.</li>
  <li><strong>Feature Implementation:</strong> Implementing an interactive image gallery, customer testimonials, and an easy-to-use reservation system.</li>
  <li><strong>Testing and Evaluation:</strong> Conducting thorough testing of all features to ensure performance, security, and responsiveness, while gathering user feedback for further improvements.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Discussion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">During the development of the Taalla Villa website, several challenges and opportunities were encountered:</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The use of React JS enabled the development of a high-performance website with smooth navigation. The implementation of an interactive image gallery and customer testimonials helped increase engagement from potential renters. However, several challenges had to be addressed:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Responsiveness:</strong> Ensuring that the landing page works well on various devices, especially small screens, required thorough testing.</li>
  <li><strong>Access Speed:</strong> Optimizing loading speeds, especially for image galleries, to ensure an uninterrupted user experience.</li>
  <li><strong>Security:</strong> Protecting user data, especially when making reservations or submitting contact forms, became a key priority in backend development.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Future development focuses include:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Additional Features:</strong> Adding new features such as direct booking or online payment integration to streamline the villa reservation process.</li>
  <li><strong>Performance Optimization:</strong> Continuously improving the speed and efficiency of the site, especially on the image gallery and other interactive features.</li>
  <li><strong>User Feedback:</strong> Utilizing user feedback to improve the interface and overall user experience.</li>
</ul>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Conclusion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The Taalla Villa website successfully demonstrates the effective use of React JS in building a responsive and interactive landing page. With the integration of an image gallery, customer testimonials, and a user-friendly reservation system, this website enhances the convenience for renters in searching and renting villas. Continuous innovation and development will ensure that this platform remains relevant and meets users' needs in the future.</p>


      `,
      ID: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Latar Belakang</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Taalla Villa adalah situs web profil yang menawarkan penyewaan villa di Kota Batu. Dibangun dengan menggunakan React JS, website ini dirancang sebagai landing page yang bertujuan untuk memberikan informasi komprehensif tentang fasilitas, lokasi, dan opsi penyewaan villa. Dengan desain modern dan responsif, Taalla Villa memberikan pengalaman pengguna yang mudah dan cepat untuk menemukan serta menyewa villa terbaik. Penggunaan React JS dalam pengembangan web ini mendukung kecepatan pemuatan yang cepat serta pengalaman navigasi yang mulus. Fitur tambahan seperti galeri gambar, testimoni, dan ketersediaan villa juga tersedia untuk memudahkan calon penyewa dalam membuat keputusan terbaik.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Pernyataan Masalah</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tantangan utama yang dihadapi dalam pengembangan website ini meliputi:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Bagaimana cara menciptakan landing page yang menarik secara visual, tetapi tetap cepat dan responsif di berbagai perangkat?</li>
  <li>Bagaimana cara menampilkan informasi villa, fasilitas, harga, dan ketersediaan dengan cara yang intuitif dan mudah diakses?</li>
  <li>Bagaimana cara memadukan galeri gambar yang interaktif dan testimoni pelanggan untuk meningkatkan kepercayaan calon penyewa?</li>
  <li>Bagaimana cara menjaga keamanan dan kecepatan akses situs saat jumlah pengunjung meningkat, terutama di musim liburan?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Ruang Lingkup Proyek</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Ruang lingkup pengembangan website Taalla Villa mencakup:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Pembangunan landing page menggunakan React JS untuk mendukung pengalaman pengguna yang cepat dan responsif.</li>
  <li>Desain antarmuka yang modern dan ramah pengguna dengan fitur navigasi intuitif.</li>
  <li>Implementasi galeri foto villa, informasi fasilitas, dan harga penyewaan yang dapat diakses dengan mudah.</li>
  <li>Menambahkan fitur testimoni dan ulasan pelanggan untuk meningkatkan kepercayaan calon penyewa.</li>
  <li>Menyediakan informasi kontak, peta lokasi, serta opsi reservasi yang langsung terhubung dengan pemilik villa.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Tujuan</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tujuan utama dari pengembangan website ini adalah:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Memberikan informasi yang komprehensif tentang villa, termasuk fasilitas, harga, dan ketersediaan, melalui landing page yang responsif dan mudah diakses.</li>
  <li>Meningkatkan daya tarik visual situs dengan galeri gambar dan testimoni pelanggan untuk menarik lebih banyak calon penyewa.</li>
  <li>Menyediakan cara mudah bagi calon penyewa untuk melakukan reservasi atau bertanya melalui fitur kontak langsung dan peta lokasi yang terintegrasi.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Manfaat</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Taalla Villa memberikan beberapa manfaat utama bagi pengunjung website:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li>Peningkatan aksesibilitas dan kenyamanan dalam mencari serta memilih villa di Kota Batu dengan informasi yang jelas dan komprehensif.</li>
  <li>Pengalaman pengguna yang ditingkatkan melalui desain antarmuka yang ramah pengguna dan cepat berkat penggunaan React JS.</li>
  <li>Kemudahan dalam melakukan reservasi dan kontak langsung dengan pemilik melalui formulir kontak dan peta lokasi yang terintegrasi.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Metodologi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan website Taalla Villa dilakukan dengan beberapa fase metodologi berikut:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Desain Sistem:</strong> Mendesain antarmuka pengguna dengan mempertimbangkan aksesibilitas dan kecepatan, serta merencanakan arsitektur menggunakan React JS untuk mendukung pengalaman navigasi yang cepat dan responsif.</li>
  <li><strong>Pembangunan Frontend:</strong> Membuat landing page interaktif dan dinamis menggunakan React JS yang didukung oleh CSS dan JavaScript untuk memastikan pengalaman pengguna yang optimal di berbagai perangkat.</li>
  <li><strong>Implementasi Fitur:</strong> Mengimplementasikan galeri gambar interaktif, testimoni pelanggan, dan sistem reservasi yang mudah digunakan.</li>
  <li><strong>Pengujian dan Evaluasi:</strong> Melakukan pengujian menyeluruh pada semua fitur untuk memastikan performa, keamanan, dan responsivitas, serta mengumpulkan umpan balik pengguna untuk perbaikan lebih lanjut.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Diskusi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Dalam pengembangan website Taalla Villa, beberapa tantangan dan peluang telah dihadapi:</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Penggunaan React JS memungkinkan pengembangan website dengan performa tinggi dan pengalaman navigasi yang mulus. Implementasi galeri gambar interaktif serta testimoni pelanggan membantu meningkatkan keterlibatan calon penyewa. Namun, ada beberapa tantangan yang harus diatasi:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Responsivitas:</strong> Memastikan bahwa landing page berfungsi dengan baik di berbagai perangkat, terutama pada layar kecil, membutuhkan pengujian yang menyeluruh.</li>
  <li><strong>Kecepatan Akses:</strong> Mengoptimalkan kecepatan loading terutama untuk galeri gambar agar tidak mengganggu pengalaman pengguna.</li>
  <li><strong>Keamanan:</strong> Melindungi data pengguna, terutama ketika mereka melakukan reservasi atau mengirimkan formulir kontak, menjadi prioritas penting dalam pengembangan backend.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Fokus pengembangan di masa depan termasuk:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
  <li><strong>Fitur Tambahan:</strong> Menambahkan fitur-fitur baru seperti pemesanan langsung atau integrasi pembayaran online untuk mempermudah proses reservasi villa.</li>
  <li><strong>Optimisasi Kinerja:</strong> Terus meningkatkan kecepatan dan efisiensi situs, terutama pada galeri gambar dan fitur interaktif lainnya.</li>
  <li><strong>Umpan Balik Pengguna:</strong> Memanfaatkan umpan balik dari pengguna untuk memperbaiki antarmuka dan pengalaman pengguna secara keseluruhan.</li>
</ul>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Kesimpulan</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Website Taalla Villa berhasil menunjukkan penggunaan React JS yang efektif dalam membangun landing page yang responsif dan interaktif. Dengan integrasi galeri gambar, testimoni pelanggan, dan sistem reservasi yang ramah pengguna, website ini meningkatkan kenyamanan penyewa dalam mencari dan menyewa villa. Inovasi dan pengembangan berkelanjutan akan memastikan platform ini tetap relevan dan memenuhi kebutuhan pengguna di masa mendatang.</p>

`,
    },
    image: '/assets/taalla-villa.png',
    tag: 'Website Developer',
    client: 'Ta Alla Villa ',
    languages: ['React JS', 'Tailwind CSS'],
    stack: ['React JS, Tailwind CSS'],
    link: 'https://taalla-villa.vercel.app/'
  },
  {
    id: '6',
    name: {
      EN: 'Study Mate V.1 ',
      ID: 'Teman Belajar V.1'
    },
    description: {
      EN: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Background</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Study Mate is a timer website designed for users to manage their study sessions efficiently. Built using React and TypeScript, the platform allows users to set a designated study time, and when the time limit is reached, an alarm signals the end of the session. Additionally, Study Mate features a to-do list system that helps users keep track of their study tasks. By leveraging React and TypeScript, the website offers a modern, responsive experience, ensuring that users can focus on their learning without any technical distractions.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Problem Statement</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The main challenges encountered during the development of Study Mate include:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>How to create a simple yet effective timer interface that is easy to use for all users?</li>
    <li>How to implement an alarm feature that effectively notifies users when their study time has elapsed?</li>
    <li>How to design a to-do list that can save tasks locally without a backend, ensuring quick access and easy management?</li>
    <li>How to ensure the website is responsive and provides a smooth user experience across various devices?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Project Scope</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The scope of the Study Mate website development includes:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Building the timer functionality using React and TypeScript to support a user-friendly experience.</li>
    <li>Designing an intuitive interface that allows users to easily set their study times and view the countdown.</li>
    <li>Implementing a local storage solution for the to-do list, allowing users to save and manage their tasks without a backend.</li>
    <li>Creating responsive designs to ensure accessibility on various devices, from desktops to mobile phones.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Objectives</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The main objectives of this website development are:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>To provide a straightforward and effective timer for study sessions, helping users manage their time efficiently.</li>
    <li>To integrate a reliable alarm feature that alerts users when their study time has expired.</li>
    <li>To create a functional to-do list that enables users to track their study tasks easily.</li>
    <li>To ensure a seamless and responsive design that caters to users on different devices.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Benefits</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Study Mate offers several key benefits to users:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Enhanced time management for study sessions through a dedicated timer and alarm system.</li>
    <li>Improved productivity with an easy-to-use to-do list that keeps track of tasks without needing a backend.</li>
    <li>Accessibility and convenience in managing study sessions and tasks on any device, ensuring users can stay focused.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Methodology</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The Study Mate website development followed several phases of methodology:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>System Design:</strong> Designing a clean user interface that prioritizes usability and speed, while planning the architecture using React and TypeScript.</li>
    <li><strong>Frontend Development:</strong> Developing the timer functionality and to-do list using React components, CSS for styling, and JavaScript for interactions.</li>
    <li><strong>Feature Implementation:</strong> Integrating the alarm feature and local storage for saving to-do tasks to ensure user data is preserved without a backend.</li>
    <li><strong>Testing and Evaluation:</strong> Conducting user testing to ensure functionality, performance, and responsiveness, while gathering feedback for future enhancements.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Discussion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">During the development of the Study Mate website, several challenges and opportunities were encountered:</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The use of React and TypeScript facilitated the creation of a responsive and interactive user experience. However, the following challenges had to be addressed:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>User Interface:</strong> Designing a user-friendly interface that simplifies the timer setup and task management required careful consideration.</li>
    <li><strong>Alarm Functionality:</strong> Ensuring that the alarm feature works reliably and alerts users promptly was essential for the timer's effectiveness.</li>
    <li><strong>Local Storage Management:</strong> Efficiently managing the local storage for the to-do list without affecting performance became a priority.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Future development focuses include:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Additional Features:</strong> Adding options for users to customize their alarms and integrate reminders for tasks.</li>
    <li><strong>Performance Optimization:</strong> Continuously improving the responsiveness and speed of the timer and to-do list features.</li>
    <li><strong>User Feedback:</strong> Incorporating user suggestions to enhance functionality and improve the overall user experience.</li>
</ul>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Conclusion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The Study Mate website successfully demonstrates the effective use of React and TypeScript in building a timer-focused application. With the implementation of a reliable timer, alarm feature, and a user-friendly to-do list system, this platform enhances productivity and supports users in managing their study sessions effectively. Ongoing development will ensure that Study Mate remains relevant and continues to meet the evolving needs of its users.</p>

      `,
      ID: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Latar Belakang</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Study Mate adalah situs web timer yang dirancang untuk membantu pengguna mengelola sesi belajar mereka dengan efisien. Dibangun menggunakan React dan TypeScript, platform ini memungkinkan pengguna untuk mengatur waktu belajar yang ditentukan, dan ketika batas waktu tercapai, alarm akan memberi sinyal bahwa sesi telah berakhir. Selain itu, Study Mate dilengkapi dengan sistem daftar tugas yang membantu pengguna melacak tugas belajar mereka. Dengan memanfaatkan React dan TypeScript, situs web ini menawarkan pengalaman modern dan responsif, memastikan bahwa pengguna dapat fokus pada pembelajaran mereka tanpa gangguan teknis.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Pernyataan Masalah</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tantangan utama yang dihadapi selama pengembangan Study Mate meliputi:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Bagaimana cara membuat antarmuka timer yang sederhana namun efektif yang mudah digunakan oleh semua pengguna?</li>
    <li>Bagaimana cara menerapkan fitur alarm yang secara efektif memberi tahu pengguna ketika waktu belajar mereka telah berakhir?</li>
    <li>Bagaimana cara merancang daftar tugas yang dapat menyimpan tugas secara lokal tanpa backend, memastikan akses cepat dan manajemen yang mudah?</li>
    <li>Bagaimana cara memastikan bahwa situs web responsif dan memberikan pengalaman pengguna yang lancar di berbagai perangkat?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Lingkup Proyek</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Lingkup pengembangan situs web Study Mate mencakup:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Membangun fungsionalitas timer menggunakan React dan TypeScript untuk mendukung pengalaman yang ramah pengguna.</li>
    <li>Merancang antarmuka intuitif yang memungkinkan pengguna dengan mudah mengatur waktu belajar mereka dan melihat hitung mundur.</li>
    <li>Menerapkan solusi penyimpanan lokal untuk daftar tugas, memungkinkan pengguna menyimpan dan mengelola tugas mereka tanpa backend.</li>
    <li>Membuat desain responsif untuk memastikan aksesibilitas di berbagai perangkat, dari desktop hingga ponsel.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Tujuan</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tujuan utama pengembangan situs web ini adalah:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Memberikan timer yang sederhana dan efektif untuk sesi belajar, membantu pengguna mengelola waktu mereka dengan efisien.</li>
    <li>Mengintegrasikan fitur alarm yang andal yang memberi tahu pengguna ketika waktu belajar mereka telah berakhir.</li>
    <li>Menciptakan daftar tugas fungsional yang memungkinkan pengguna untuk melacak tugas belajar mereka dengan mudah.</li>
    <li>Memastikan desain yang mulus dan responsif yang memenuhi kebutuhan pengguna di berbagai perangkat.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Manfaat</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Study Mate menawarkan beberapa manfaat utama bagi pengguna:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Peningkatan manajemen waktu untuk sesi belajar melalui sistem timer dan alarm yang didedikasikan.</li>
    <li>Peningkatan produktivitas dengan daftar tugas yang mudah digunakan yang melacak tugas tanpa memerlukan backend.</li>
    <li>Aksesibilitas dan kenyamanan dalam mengelola sesi belajar dan tugas di perangkat apa pun, memastikan pengguna tetap fokus.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Metodologi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan situs web Study Mate mengikuti beberapa fase metodologi:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Desain Sistem:</strong> Merancang antarmuka pengguna yang bersih yang memprioritaskan kegunaan dan kecepatan, sambil merencanakan arsitektur menggunakan React dan TypeScript.</li>
    <li><strong>Pengembangan Frontend:</strong> Mengembangkan fungsionalitas timer dan daftar tugas menggunakan komponen React, CSS untuk styling, dan JavaScript untuk interaksi.</li>
    <li><strong>Implementasi Fitur:</strong> Mengintegrasikan fitur alarm dan penyimpanan lokal untuk menyimpan tugas daftar tugas untuk memastikan data pengguna terjaga tanpa backend.</li>
    <li><strong>Pengujian dan Evaluasi:</strong> Melakukan pengujian pengguna untuk memastikan fungsionalitas, kinerja, dan responsivitas, sambil mengumpulkan umpan balik untuk perbaikan di masa depan.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Diskusi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Selama pengembangan situs web Study Mate, beberapa tantangan dan peluang telah dihadapi:</p>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Penggunaan React dan TypeScript memfasilitasi penciptaan pengalaman pengguna yang responsif dan interaktif. Namun, tantangan berikut harus diatasi:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Antarmuka Pengguna:</strong> Merancang antarmuka yang ramah pengguna yang menyederhanakan pengaturan timer dan manajemen tugas memerlukan pertimbangan yang cermat.</li>
    <li><strong>Fungsionalitas Alarm:</strong> Memastikan bahwa fitur alarm bekerja secara andal dan memberi tahu pengguna dengan cepat sangat penting untuk efektivitas timer.</li>
    <li><strong>Manajemen Penyimpanan Lokal:</strong> Mengelola penyimpanan lokal untuk daftar tugas tanpa mempengaruhi kinerja menjadi prioritas.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Fokus pengembangan di masa depan meliputi:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Fitur Tambahan:</strong> Menambahkan opsi bagi pengguna untuk menyesuaikan alarm mereka dan mengintegrasikan pengingat untuk tugas.</li>
    <li><strong>Optimisasi Kinerja:</strong> Terus meningkatkan responsivitas dan kecepatan fitur timer dan daftar tugas.</li>
    <li><strong>Umpan Balik Pengguna:</strong> Mengintegrasikan saran pengguna untuk meningkatkan fungsionalitas dan meningkatkan pengalaman pengguna secara keseluruhan.</li>
</ul>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Kesimpulan</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Situs web Study Mate berhasil menunjukkan penggunaan efektif React dan TypeScript dalam membangun aplikasi yang berfokus pada timer. Dengan penerapan timer yang andal, fitur alarm, dan sistem daftar tugas yang ramah pengguna, platform ini meningkatkan produktivitas dan mendukung pengguna dalam mengelola sesi belajar mereka secara efektif. Pengembangan yang berkelanjutan akan memastikan bahwa Study Mate tetap relevan dan terus memenuhi kebutuhan pengguna yang terus berkembang.</p>


`,
    },
    image: '/assets/study-mate.png',
    tag: 'Website Developer',
    client: '- ',
    languages: ['React Typescript', 'Bootstrap'],
    stack: ['React Typescript, Bootstrap'],
    link: 'https://simple-study-mate.vercel.app/'
  },
  {
    id: '7',
    name: {
      EN: 'CBT Exam ',
      ID: 'Ujian CBT'
    },
    description: {
      EN: `
        <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Background</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The CBT Exam project is a web-based examination platform developed using CodeIgniter and Bootstrap. This platform caters to students, allowing them to take tests in various subjects or categories. Currently under development, it features user authentication sessions, a question timer, multiple-choice questions, question navigation, and a results page that displays the user's name, subject category, and score. The design prioritizes responsiveness and user-friendliness, ensuring an optimal experience across devices.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Problem Statement</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Key challenges encountered during the development of the CBT Exam platform include:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>How to implement an efficient user authentication system for secure access?</li>
    <li>How to design a clear interface for displaying exam questions and navigation options?</li>
    <li>How to integrate a timer for managing the exam duration effectively?</li>
    <li>How to ensure responsive design across various devices while maintaining usability?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Project Scope</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The development scope of the CBT Exam platform includes:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Implementing user authentication to manage secure access to the exam platform.</li>
    <li>Designing an intuitive interface that effectively presents exam questions and navigation.</li>
    <li>Integrating a timer for monitoring the time allotted for each exam.</li>
    <li>Creating responsive web pages to ensure accessibility on different devices.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Objectives</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The main objectives of this project are:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>To provide a secure and user-friendly authentication system for students.</li>
    <li>To deliver a seamless interface for managing exam questions and responses.</li>
    <li>To implement a timer feature to assist users in managing their exam time effectively.</li>
    <li>To ensure a responsive design for accessibility across various devices.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Benefits</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The CBT Exam platform offers several key benefits to users:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Secure access through a robust user authentication system.</li>
    <li>A streamlined interface for easy navigation and question management.</li>
    <li>A timer feature to help users manage their exam duration effectively.</li>
    <li>Accessibility on any device, ensuring users can take exams conveniently.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Methodology</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The development methodology for the CBT Exam platform includes the following phases:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>System Design:</strong> Creating a user-friendly interface that emphasizes usability while planning the architecture using CodeIgniter and Bootstrap.</li>
    <li><strong>Frontend Development:</strong> Building the exam interface using Bootstrap for styling and CodeIgniter for backend functionality.</li>
    <li><strong>Feature Implementation:</strong> Integrating authentication, timer, and question management functionalities.</li>
    <li><strong>Testing and Evaluation:</strong> Conducting user testing to ensure the platform is functional, responsive, and user-friendly.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Discussion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The development of the CBT Exam platform has revealed several challenges and opportunities:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>User Interface:</strong> Designing a user-friendly interface that simplifies exam navigation and management required careful planning.</li>
    <li><strong>Authentication and Security:</strong> Ensuring a secure and reliable authentication process was critical for user trust.</li>
    <li><strong>Performance Optimization:</strong> Continuously improving the platform’s performance to handle multiple users effectively.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Future developments will focus on:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Enhanced Features:</strong> Adding options for users to customize their exam experience and results tracking.</li>
    <li><strong>Performance Improvements:</strong> Optimizing speed and responsiveness for a better user experience.</li>
    <li><strong>Feedback Incorporation:</strong> Gathering user feedback to enhance functionality and usability.</li>
</ul>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Conclusion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The CBT Exam platform effectively demonstrates the capabilities of CodeIgniter and Bootstrap in creating a robust examination system. With secure user authentication, a reliable exam timer, and a user-friendly interface, this platform enhances the exam experience for students. Ongoing development will ensure that the CBT Exam platform remains effective and continues to meet the changing needs of its users.</p>

      `,
      ID: `
       <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Latar Belakang</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Projek CBT Exam merupakan platform ujian berbasis web yang dikembangkan menggunakan CodeIgniter dan Bootstrap. Platform ini ditujukan untuk siswa, memungkinkan mereka mengikuti ujian dalam berbagai mata pelajaran atau kategori. Saat ini masih dalam pengembangan, platform ini memiliki fitur autentikasi pengguna, timer ujian, soal pilihan ganda, navigasi soal, dan halaman hasil yang menampilkan nama pengguna, kategori mata pelajaran, dan skor. Desainnya memprioritaskan responsivitas dan kemudahan penggunaan, memastikan pengalaman optimal di berbagai perangkat.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Pernyataan Masalah</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tantangan utama yang dihadapi selama pengembangan platform CBT Exam meliputi:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Bagaimana cara mengimplementasikan sistem autentikasi pengguna yang efisien untuk akses yang aman?</li>
    <li>Bagaimana merancang antarmuka yang jelas untuk menampilkan soal ujian dan opsi navigasi?</li>
    <li>Bagaimana mengintegrasikan timer untuk mengelola durasi ujian secara efektif?</li>
    <li>Bagaimana memastikan desain responsif di berbagai perangkat sambil mempertahankan kegunaan?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Ruang Lingkup Proyek</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Ruang lingkup pengembangan platform CBT Exam mencakup:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Menerapkan autentikasi pengguna untuk mengelola akses yang aman ke platform ujian.</li>
    <li>Merancang antarmuka intuitif yang secara efektif menyajikan soal ujian dan navigasi.</li>
    <li>Mengintegrasikan timer untuk memantau waktu yang dialokasikan untuk setiap ujian.</li>
    <li>Membuat halaman web responsif untuk memastikan aksesibilitas di berbagai perangkat.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Tujuan</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tujuan utama dari proyek ini adalah:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Memberikan sistem autentikasi yang aman dan ramah pengguna untuk siswa.</li>
    <li>Menawarkan antarmuka yang mulus untuk mengelola soal ujian dan respons.</li>
    <li>Menerapkan fitur timer untuk membantu pengguna mengelola waktu ujian mereka dengan efektif.</li>
    <li>Memastikan desain responsif untuk aksesibilitas di berbagai perangkat.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Manfaat</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Platform CBT Exam menawarkan beberapa manfaat utama bagi pengguna:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Akses yang aman melalui sistem autentikasi pengguna yang kuat.</li>
    <li>Antarmuka yang disederhanakan untuk navigasi dan manajemen soal yang mudah.</li>
    <li>Fitur timer untuk membantu pengguna mengelola durasi ujian secara efektif.</li>
    <li>Aksesibilitas di perangkat manapun, memastikan pengguna dapat mengikuti ujian dengan nyaman.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Metodologi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Metodologi pengembangan untuk platform CBT Exam mencakup beberapa fase berikut:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Desain Sistem:</strong> Membuat antarmuka yang ramah pengguna yang menekankan pada kegunaan sambil merencanakan arsitektur menggunakan CodeIgniter dan Bootstrap.</li>
    <li><strong>Pembangunan Frontend:</strong> Membangun antarmuka ujian menggunakan Bootstrap untuk styling dan CodeIgniter untuk fungsionalitas backend.</li>
    <li><strong>Implementasi Fitur:</strong> Mengintegrasikan autentikasi, timer, dan fungsionalitas manajemen soal.</li>
    <li><strong>Penguji dan Evaluasi:</strong> Melakukan pengujian pengguna untuk memastikan platform berfungsi, responsif, dan ramah pengguna.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Diskusi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan platform CBT Exam telah mengungkap beberapa tantangan dan peluang:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Antarmuka Pengguna:</strong> Merancang antarmuka yang ramah pengguna yang menyederhanakan navigasi dan manajemen ujian memerlukan perencanaan yang cermat.</li>
    <li><strong>Autentikasi dan Keamanan:</strong> Memastikan proses autentikasi yang aman dan dapat diandalkan sangat penting untuk membangun kepercayaan pengguna.</li>
    <li><strong>Optimasi Kinerja:</strong> Secara terus-menerus meningkatkan kinerja platform untuk menangani banyak pengguna secara efektif.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan di masa depan akan fokus pada:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Fitur yang Ditingkatkan:</strong> Menambahkan opsi bagi pengguna untuk menyesuaikan pengalaman ujian dan pelacakan hasil.</li>
    <li><strong>Peningkatan Kinerja:</strong> Mengoptimalkan kecepatan dan responsivitas untuk pengalaman pengguna yang lebih baik.</li>
    <li><strong>Penerapan Umpan Balik:</strong> Mengumpulkan umpan balik pengguna untuk meningkatkan fungsionalitas dan kegunaan.</li>
</ul>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Kesimpulan</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Platform CBT Exam secara efektif menunjukkan kemampuan CodeIgniter dan Bootstrap dalam menciptakan sistem ujian yang kokoh. Dengan autentikasi pengguna yang aman, timer ujian yang dapat diandalkan, dan antarmuka yang ramah pengguna, platform ini meningkatkan pengalaman ujian bagi siswa. Pengembangan yang berkelanjutan akan memastikan bahwa platform CBT Exam tetap efektif dan terus memenuhi kebutuhan pengguna yang berkembang.</p>


`,
    },
    image: '/assets/cbt-exam.png',
    tag: 'Website Developer',
    client: '- ',
    languages: ['Codeigniter', 'Bootstrap'],
    stack: ['CodeIgniter, Bootstrap'],
    link: 'https://github.com/gigarazkiarianda/cbt-exam'
  },
  {
    id: '8',
    name: {
      EN: 'Warehouse Dashboard ',
      ID: 'Dashboard Gudang'
    },
    description: {
      EN: `
       <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Background</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The Warehouse Dashboard project is a web-based management platform developed using Laravel and Bootstrap. This platform is designed for managing warehouse operations, providing an admin dashboard to view statistics related to products, warehouses, and users. Currently under development, it features product stock, pricing, names, descriptions, warehouse locations, and capacities. The design prioritizes responsiveness and user-friendliness, ensuring an optimal experience across devices.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Problem Statement</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Key challenges encountered during the development of the Warehouse Dashboard include:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>How to implement a user role system for secure access to different dashboard features?</li>
    <li>How to design a clear interface for displaying product and warehouse statistics?</li>
    <li>How to manage real-time updates for stock and capacity information?</li>
    <li>How to ensure responsive design across various devices while maintaining usability?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Project Scope</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The development scope of the Warehouse Dashboard includes:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Implementing user authentication and role management to secure access to the dashboard.</li>
    <li>Designing an intuitive interface that effectively presents product and warehouse statistics.</li>
    <li>Integrating real-time updates for managing stock levels and warehouse capacity.</li>
    <li>Creating responsive web pages to ensure accessibility on different devices.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Objectives</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The main objectives of this project are:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>To provide a secure authentication system for users based on their roles.</li>
    <li>To deliver a seamless interface for managing product and warehouse information.</li>
    <li>To implement real-time updates to assist users in monitoring stock and capacity.</li>
    <li>To ensure a responsive design for accessibility across various devices.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Benefits</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The Warehouse Dashboard offers several key benefits to users:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Secure access through a robust user authentication and role management system.</li>
    <li>A streamlined interface for easy navigation and management of products and warehouses.</li>
    <li>Real-time updates for stock and capacity to improve operational efficiency.</li>
    <li>Accessibility on any device, ensuring users can manage warehouse operations conveniently.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Methodology</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The development methodology for the Warehouse Dashboard includes the following phases:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>System Design:</strong> Creating a user-friendly interface that emphasizes usability while planning the architecture using Laravel and Bootstrap.</li>
    <li><strong>Frontend Development:</strong> Building the dashboard interface using Bootstrap for styling and Laravel for backend functionality.</li>
    <li><strong>Feature Implementation:</strong> Integrating authentication, role management, and real-time updates for product and warehouse information.</li>
    <li><strong>Testing and Evaluation:</strong> Conducting user testing to ensure the platform is functional, responsive, and user-friendly.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Discussion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The development of the Warehouse Dashboard has revealed several challenges and opportunities:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>User Interface:</strong> Designing a user-friendly interface that simplifies warehouse management required careful planning.</li>
    <li><strong>Authentication and Security:</strong> Ensuring a secure and reliable authentication process was critical for user trust.</li>
    <li><strong>Performance Optimization:</strong> Continuously improving the platform’s performance to handle multiple users effectively.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Future developments will focus on:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Enhanced Features:</strong> Adding options for users to customize their dashboard experience and reporting.</li>
    <li><strong>Performance Improvements:</strong> Optimizing speed and responsiveness for a better user experience.</li>
    <li><strong>Feedback Incorporation:</strong> Gathering user feedback to enhance functionality and usability.</li>
</ul>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Conclusion</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">The Warehouse Dashboard effectively demonstrates the capabilities of Laravel and Bootstrap in creating a robust warehouse management system. With secure user authentication, real-time updates, and a user-friendly interface, this platform enhances operational efficiency for users. Ongoing development will ensure that the Warehouse Dashboard remains effective and continues to meet the changing needs of its users.</p>

      `,
      ID: `
       <h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Latar Belakang</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Proyek Dashboard Gudang adalah platform manajemen berbasis web yang dikembangkan menggunakan Laravel dan Bootstrap. Platform ini dirancang untuk mengelola operasi gudang, menyediakan dashboard admin untuk melihat statistik terkait produk, gudang, dan pengguna. Saat ini dalam tahap pengembangan, platform ini memiliki fitur stok produk, harga, nama, deskripsi, lokasi gudang, dan kapasitas. Desainnya memprioritaskan responsivitas dan kemudahan penggunaan, memastikan pengalaman optimal di berbagai perangkat.</p>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Pernyataan Masalah</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tantangan utama yang dihadapi selama pengembangan Dashboard Gudang meliputi:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Bagaimana cara mengimplementasikan sistem peran pengguna untuk akses yang aman ke berbagai fitur dashboard?</li>
    <li>Bagaimana merancang antarmuka yang jelas untuk menampilkan statistik produk dan gudang?</li>
    <li>Bagaimana mengelola pembaruan real-time untuk informasi stok dan kapasitas?</li>
    <li>Bagaimana memastikan desain responsif di berbagai perangkat sambil mempertahankan kegunaan?</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Ruang Lingkup Proyek</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Ruang lingkup pengembangan Dashboard Gudang meliputi:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Mengimplementasikan otentikasi pengguna dan manajemen peran untuk mengamankan akses ke dashboard.</li>
    <li>Merancang antarmuka intuitif yang efektif menyajikan statistik produk dan gudang.</li>
    <li>Mengintegrasikan pembaruan real-time untuk mengelola tingkat stok dan kapasitas gudang.</li>
    <li>Membuat halaman web responsif untuk memastikan aksesibilitas di berbagai perangkat.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Tujuan</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Tujuan utama proyek ini adalah:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Memberikan sistem otentikasi yang aman untuk pengguna berdasarkan peran mereka.</li>
    <li>Menawarkan antarmuka yang mulus untuk mengelola informasi produk dan gudang.</li>
    <li>Menerapkan fitur pembaruan waktu nyata untuk membantu pengguna dalam memantau stok dan kapasitas.</li>
    <li>Memastikan desain responsif agar dapat diakses di berbagai perangkat.</li>
</ol>

<h2 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Manfaat</strong></h2>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Dashboard Gudang menawarkan beberapa manfaat utama bagi pengguna:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li>Akses yang aman melalui sistem otentikasi pengguna dan manajemen peran yang kuat.</li>
    <li>Antarmuka yang disederhanakan untuk navigasi dan manajemen produk serta gudang yang mudah.</li>
    <li>Pembaruan waktu nyata untuk stok dan kapasitas guna meningkatkan efisiensi operasional.</li>
    <li>Aksesibilitas di perangkat apa pun, memastikan pengguna dapat mengelola operasi gudang dengan nyaman.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Metodologi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Metodologi pengembangan untuk Dashboard Gudang meliputi fase-fase berikut:</p>
<ol style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Desain Sistem:</strong> Membuat antarmuka yang ramah pengguna yang menekankan kegunaan sambil merencanakan arsitektur menggunakan Laravel dan Bootstrap.</li>
    <li><strong>Pembangunan Frontend:</strong> Membangun antarmuka dashboard menggunakan Bootstrap untuk styling dan Laravel untuk fungsi backend.</li>
    <li><strong>Implementasi Fitur:</strong> Mengintegrasikan otentikasi, manajemen peran, dan pembaruan waktu nyata untuk informasi produk dan gudang.</li>
    <li><strong>Pengujian dan Evaluasi:</strong> Melakukan pengujian pengguna untuk memastikan platform berfungsi, responsif, dan ramah pengguna.</li>
</ol>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Diskusi</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan Dashboard Gudang telah mengungkapkan beberapa tantangan dan peluang:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Antarmuka Pengguna:</strong> Merancang antarmuka yang ramah pengguna yang menyederhanakan manajemen gudang memerlukan perencanaan yang cermat.</li>
    <li><strong>Otentikasi dan Keamanan:</strong> Memastikan proses otentikasi yang aman dan dapat diandalkan sangat penting untuk kepercayaan pengguna.</li>
    <li><strong>Optimisasi Kinerja:</strong> Terus meningkatkan kinerja platform untuk menangani banyak pengguna secara efektif.</li>
</ul>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Pengembangan masa depan akan fokus pada:</p>
<ul style="font-size: 20px; text-align: justify; margin-bottom: 20px;">
    <li><strong>Fitur yang Ditingkatkan:</strong> Menambahkan opsi bagi pengguna untuk menyesuaikan pengalaman dashboard mereka dan pelaporan.</li>
    <li><strong>Peningkatan Kinerja:</strong> Mengoptimalkan kecepatan dan responsivitas untuk pengalaman pengguna yang lebih baik.</li>
    <li><strong>Inkorporasi Umpan Balik:</strong> Mengumpulkan umpan balik pengguna untuk meningkatkan fungsi dan kegunaan.</li>
</ul>

<h1 style="font-size: 28px; text-align: center; margin-bottom: 20px;"><strong>Kesimpulan</strong></h1>
<p style="font-size: 20px; text-align: justify; margin-bottom: 20px;">Dashboard Gudang secara efektif menunjukkan kemampuan Laravel dan Bootstrap dalam menciptakan sistem manajemen gudang yang kuat. Dengan otentikasi pengguna yang aman, pembaruan waktu nyata, dan antarmuka yang ramah pengguna, platform ini meningkatkan efisiensi operasional bagi pengguna. Pengembangan yang berkelanjutan akan memastikan bahwa Dashboard Gudang tetap efektif dan terus memenuhi kebutuhan pengguna yang berubah.</p>


`,
    },
    image: '/assets/warehouse-project.png',
    tag: 'Website Developer',
    client: '- ',
    languages: ['Laravel', 'Bootstrap'],
    stack: ['Laravel, Bootstrap'],
    link: 'https://github.com/gigarazkiarianda/warehouse-project'
  },
];




